import { render, staticRenderFns } from "./DefaultDialogs.vue?vue&type=template&id=688b9898&"
import script from "./DefaultDialogs.vue?vue&type=script&lang=ts&"
export * from "./DefaultDialogs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports