export default [
  {
    value: "ready",
    label: "대기중",
    disabled: false,
  },
  {
    value: "used",
    label: "렌트중",
    disabled: false,
  },
  {
    value: "broken",
    label: "불량",
    disabled: false,
  },
];
