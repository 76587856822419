import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { StorageKey } from "@/consts/StorageKey";
import StorageUtil from "@/utils/StorageUtil";

const HOST = window.location.host;

if (HOST === "localhost:8000" || HOST === "localhost:8080") {
  //테스트 모드
  Vue.config.devtools = true;
} else {
  //라이브 모드
  if (window.location.protocol == "http:") {
    window.location.href = window.location.href.replace("http:", "https:");
  }
}

if (window.location.hostname == "localhost") {
  axios.defaults.baseURL = "http://localhost:8000/api/";
} else {
  axios.defaults.baseURL = window.location.origin + "/api/";
}

const token: any = document.head.querySelector('meta[name="csrf-token"]');

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": token ? token : "",
  Accept: "application/json",
  Authorization: "Bearer " + StorageUtil.getString(StorageKey.API_TOKEN),
};

Vue.use(VueAxios, axios);
