import Vue from "vue";
import VueRouter from "vue-router";

import adminRoutes from "./admin";
import clientRoutes from "./client";
import partnerRoutes from "./partner";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "login" },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
  },
  adminRoutes,
  clientRoutes,
  partnerRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
