import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
  configure,
} from "vee-validate";
import ko from "vee-validate/dist/locale/ko.json";
import {
  required,
  min,
  max,
  between,
  email,
  numeric,
  digits,
  confirmed,
  min_value,
} from "vee-validate/dist/rules";

extend("min_value", { ...min_value });
extend("required", { ...required });
extend("min", { ...min });
extend("max", { ...max });
extend("between", { ...between });
extend("email", { ...email });
extend("numeric", { ...numeric });
extend("digits", { ...digits });
extend("confirmed", { ...confirmed });
extend("image", {
  message: function () {
    return "파일이 이미지 형식이 아닙니다.";
  },
  validate: function (value: string) {
    return /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(value);
  },
});
extend("phone", {
  message: function () {
    return "휴대폰번호를 확인해주세요. 예시) 01012341234.";
  },
  validate: function (value: string) {
    return /^01([0|1|6|7|8|9]?)([0-9]{8,9})$/.test(value);
  },
});
extend("date_format", {
  message: function () {
    return "날짜형식을 확인해주세요. 예시)2000-01-01";
  },
  validate: function (value: string) {
    return /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/.test(
      value
    );
  },
});
extend("password", {
  message: function () {
    return "암호는 대문자 1개, 소문자 1개, 숫자 1개 및 특수 문자 1개 이상을 포함해야 합니다(예: . _ & ? 등).";
  },
  validate: function (value: string) {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return strongRegex.test(value);
  },
});

configure({ bails: false });
localize("ko", ko);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
