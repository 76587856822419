import { RouteConfig } from "vue-router";

const root = "/client";

const routes: RouteConfig = {
  path: root,
  name: "client",
  component: () => import(`@/views/Main.vue`),
  redirect: { name: "client.dashboard" },
  children: [
    {
      path: "dashboard",
      name: "client.dashboard",
      component: () => import("@/views/client/Main.vue"),
    },
  ],
};

export default routes;
