import { RouteConfig } from "vue-router";

const root = "/partner";

const routes: RouteConfig = {
  path: root,
  name: "partner",
  component: () => import(`@/views/Main.vue`),
  children: [
    {
      path: "stock/selling",
      name: "partner.stock.selling",
      component: () => import("@/views/partner/stock/SellingMain.vue"),
    },
    {
      path: "stock/rent",
      name: "partner.stock.rent",
      component: () => import("@/views/partner/stock/RentMain.vue"),
    },
    {
      path: "client/care",
      name: "partner.client.care",
      component: () => import("@/views/partner/client/Main.vue"),
    },
  ],
};

export default routes;
