









































import { Vue, Component, Emit } from "vue-property-decorator";

export interface ConfirmCallback {
  (result: boolean): void;
}

@Component
export default class ConfirmDialog extends Vue {
  @Emit()
  private confirm(result: boolean) {
    this.visible = false;
    if (this.callback) this.callback(result);
    return result;
  }

  private visible = false;

  private title = "알림";
  private message = "메시지";
  private callback!: ConfirmCallback;

  public show(title: string, message: string, callback: ConfirmCallback) {
    this.title = title;
    this.message = message;
    this.visible = true;
    this.callback = callback;
  }
}
