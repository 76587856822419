
















import { Vue, Component } from "vue-property-decorator";
import AlertDialog, { AlertCallback } from "./AlertDialog.vue";
import ConfirmDialog, { ConfirmCallback } from "./ConfirmDialog.vue";
import ProgressDialog from "./ProgressDialog.vue";

@Component({
  components: {
    AlertDialog,
    ConfirmDialog,
    ProgressDialog,
  },
})
export default class DefaultDialogs extends Vue {
  protected alertVisible = false;
  protected confirmVisible = false;
  protected progressVisible = false;

  public showAlert(title: string, message: string, callback?: AlertCallback) {
    this.alertVisible = true;
    this.$nextTick(() => {
      const dialog: AlertDialog = this.$refs.alert as AlertDialog;
      if (callback == null) {
        dialog.show(title, message);
      } else {
        dialog.show(title, message, callback);
      }
    });
  }

  public showConfirm(
    title: string,
    message: string,
    callback: ConfirmCallback
  ) {
    this.confirmVisible = true;
    this.$nextTick(() => {
      const dialog: ConfirmDialog = this.$refs.confirm as ConfirmDialog;
      dialog.show(title, message, callback);
    });
  }

  public showProgress() {
    this.progressVisible = true;
  }

  public hideProgress() {
    this.progressVisible = false;
  }
}
