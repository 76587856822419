








import { Vue, Component } from "vue-property-decorator";
import DefaultDialogs from "@/components/dialog/DefaultDialogs.vue";
import DefaultSnackbar from "@/components/snackbar/DefaultSnackbar.vue";

@Component({
  components: {
    DefaultDialogs,
    DefaultSnackbar,
  },
})
export default class App extends Vue {
  public mounted(): void {
    if (!Object.prototype.hasOwnProperty.call(Vue.prototype, "$dialog")) {
      Vue.prototype.$dialog = this.$refs.defaultDialogs;
    }

    if (!Object.prototype.hasOwnProperty.call(Vue.prototype, "$snackbar")) {
      Vue.prototype.$snackbar = this.$refs.defaultSnackbar;
    }
  }
}

declare module "vue/types/vue" {
  interface Vue {
    $dialog: typeof DefaultDialogs;
    $snackbar: typeof DefaultSnackbar;
  }
}
