type ProductCategoryState = {
  value: string;
  label: string;
};

const states: ProductCategoryState[] = [
  {
    value: "sell",
    label: "판매",
  },
  {
    value: "rent",
    label: "렌트",
  },
];

export default states;
