































import { Vue, Component, Emit } from "vue-property-decorator";

export interface AlertCallback {
  (): void;
}

@Component
export default class AlertDialog extends Vue {
  @Emit()
  private confirm() {
    this.visible = false;
    if (this.callback) this.callback();
    return null;
  }

  private title = "알림";
  private message = "메시지";

  private visible = false;
  private callback!: AlertCallback;

  public show(title: string, message: string, callback?: AlertCallback) {
    this.title = title;
    this.message = message;
    this.visible = true;

    if (callback != null) this.callback = callback;
  }
}
