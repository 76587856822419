export default class StorageUtil {
  public static setObject(key: string, obj: any) {
    const str: any = JSON.stringify(obj);
    localStorage.setItem(key, str);
  }

  public static getObject(key: string): any {
    const str: string = localStorage.getItem(key) as string;
    return JSON.parse(str);
  }

  public static setString(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public static getString(key: string): string {
    return localStorage.getItem(key) as string;
  }
}
