export type CsRequestState = {
  value: string;
  label: string;
};

const states: CsRequestState[] = [
  {
    value: "",
    label: "전체",
  },
  {
    value: "receipt",
    label: "접수완료",
  },
  {
    value: "progress",
    label: "처리중",
  },
  {
    value: "finished",
    label: "처리완료",
  },
  {
    value: "impossible",
    label: "처리불가",
  },
];

export default states;
