import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import ko from "vuetify/src/locale/ko";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { ko },
    current: "ko",
  },
  theme: {
    themes: {
      light: {
        primary: "#455A64",
        secondary: "#03A9F4",
        lightgray: "#909090",
        accent: "#03A9F4",
        error: "#b71c1c",
      },
    },
  },
});
