import { RouteConfig } from "vue-router";

const root = "/admin";

const routes: RouteConfig = {
  path: root,
  name: "admin",
  component: () => import(`@/views/Main.vue`),
  redirect: { name: "admin.dashboard" },
  children: [
    {
      path: "dashboard",
      name: "admin.dashboard",
      component: () => import("@/views/admin/Main.vue"),
    },
    {
      path: "stock/selling",
      name: "admin.stock.selling",
      component: () => import("@/views/admin/stock/SellingMain.vue"),
    },
    {
      path: "stock/rent",
      name: "admin.stock.rent",
      component: () => import("@/views/admin/stock/RentMain.vue"),
    },
    {
      path: "cs/main",
      name: "admin.cs.main",
      component: () => import("@/views/admin/cs/Main.vue"),
    },
    {
      path: "client/main",
      name: "admin.client.main",
      component: () => import("@/views/admin/client/Main.vue"),
    },
    {
      path: "products/category",
      name: "admin.product.category",
      component: () => import("@/views/admin/product/CategoryMain.vue"),
    },
    {
      path: "products/product",
      name: "admin.product.product",
      component: () => import("@/views/admin/product/ProductMain.vue"),
    },
  ],
};

export default routes;
