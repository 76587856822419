import csRequestStates from "@/consts/CsRequestStates";
import ProductCategoryStates from "@/consts/ProductCategoryStates";
import RentStockStates from "@/consts/RentStockStates";
import { CsStateType } from "@/models/Cs";
import { ProductType } from "@/models/Product";
import { RentStockStateType } from "@/models/RentStock";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * 예시 
 * 
    export function ex() {
      return "일이삼사";
    }
    
    사용할때
    {{ "testa" | test }}
 * 
 */

export const csRequestState = (state: CsStateType) => {
  const idx = csRequestStates.findIndex((data) => data.value === state);
  return csRequestStates[idx].label;
};

export const productCategoryState = (state: ProductType) => {
  const idx = ProductCategoryStates.findIndex((data) => data.value === state);
  return ProductCategoryStates[idx].label;
};

export const rentStockState = (state: RentStockStateType) => {
  const idx = RentStockStates.findIndex((data) => data.value === state);
  return RentStockStates[idx].label;
};
