import _Vue from "vue";
import { SnackbarConfigs } from "@/view_models/ComponentUI";

interface ConfirmCallback {
  (result: boolean): void;
}
interface AlertCallback {
  (): void;
}
export class SimpleAlert {
  public static showSnack(msg: string, config?: SnackbarConfigs) {
    return new Promise(() => {
      _Vue.prototype.$snackbar.showSnack(msg, config);
    });
  }

  public static hideSnack() {
    return new Promise(() => {
      _Vue.prototype.$snackbar.hide();
    });
  }

  public static alert(
    title?: string,
    message?: string,
    callback?: AlertCallback
  ) {
    return new Promise(() => {
      _Vue.prototype.$dialog.showAlert(title, message, callback);
    });
  }

  public static confirm(
    title?: string,
    message?: string,
    callback?: ConfirmCallback
  ) {
    _Vue.prototype.$dialog.showConfirm(title, message, callback);
  }

  public static showProgress() {
    _Vue.prototype.$dialog.showProgress();
  }

  public static hideProgress() {
    _Vue.prototype.$dialog.hideProgress();
  }

  static install(Vue: typeof _Vue): void {
    Vue.showSnack = SimpleAlert.showSnack;
    Vue.hideSnack = SimpleAlert.hideSnack;
    Vue.alert = SimpleAlert.alert;
    Vue.confirm = SimpleAlert.confirm;
    Vue.showProgress = SimpleAlert.showProgress;
    Vue.hideProgress = SimpleAlert.hideProgress;

    const prototype = Vue.prototype;

    if (!Object.prototype.hasOwnProperty.call(prototype, "$showSnack")) {
      prototype.$showSnack = SimpleAlert.showSnack;
    }

    if (!Object.prototype.hasOwnProperty.call(prototype, "$hideSnack")) {
      prototype.$hideSnack = SimpleAlert.hideSnack;
    }

    if (!Object.prototype.hasOwnProperty.call(prototype, "$alert")) {
      prototype.$alert = SimpleAlert.alert;
    }

    if (!Object.prototype.hasOwnProperty.call(prototype, "$alert")) {
      prototype.$alert = SimpleAlert.alert;
    }

    if (!Object.prototype.hasOwnProperty.call(prototype, "$confirm")) {
      prototype.$confirm = SimpleAlert.confirm;
    }

    if (!Object.prototype.hasOwnProperty.call(prototype, "$showProgress")) {
      prototype.$showProgress = SimpleAlert.showProgress;
    }

    if (!Object.prototype.hasOwnProperty.call(prototype, "$hideProgress")) {
      prototype.$hideProgress = SimpleAlert.hideProgress;
    }
  }
}

declare module "vue/types/vue" {
  interface Vue {
    $showSnack: typeof SimpleAlert.showSnack;
    $hideSnack: typeof SimpleAlert.hideSnack;
    $alert: typeof SimpleAlert.alert;
    $confirm: typeof SimpleAlert.confirm;
    $showProgress: typeof SimpleAlert.showProgress;
    $hideProgress: typeof SimpleAlert.hideProgress;
  }

  interface VueConstructor {
    showSnack: typeof SimpleAlert.showSnack;
    hideSnack: typeof SimpleAlert.hideSnack;
    alert: typeof SimpleAlert.alert;
    confirm: typeof SimpleAlert.confirm;
    showProgress: typeof SimpleAlert.showProgress;
    hideProgress: typeof SimpleAlert.hideProgress;
  }
}

_Vue.use(SimpleAlert);
